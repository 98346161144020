.dialog-full .MuiPaper-root {
  max-height: none !important;
  max-width: none !important;
  border-radius: 0;
}

.dialog-full {
  width: 100vw;
  height: 100vh;
}
