.react-pdf__Page {
  min-width: auto !important;
}
.PDFPage > canvas {
  width: 100% !important;
  height: auto !important;
}
.react-pdf__Page__svg {
  width: 100% !important;
  height: auto !important;
}
.react-pdf__Page__svg > svg {
  width: 100% !important;
  height: auto !important;
}
