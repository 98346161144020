.lang-btn {
  text-align: center;
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 9999;
  background: #fff;
}

.center {
  display: flex;
  /* establish flex container */
  flex-direction: column;
  /* make main axis vertical */
  justify-content: center;
  /* center items vertically, in this case */
  align-items: center;
  /* center items horizontally, in this case */
  height: 100vh;
}
