.header {
  height: 52px;
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.arrow {
  width: 24px;
  height: 24px;
  margin-left: 16px;
}

.title {
  font-size: 20px;
  font-weight: 600;
  padding-left: 20px;
}

.countryListContainer {
  overflow-y: auto;
  padding-left: 24px;
  padding-right: 24px;
  max-height: 100%;
  padding-bottom: 100px;
}

.countryList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.item {
  padding: 12px;
  border-bottom: 1px solid #f5f5f5;
}
.selectItem {
  padding: 12px;
  color: red;
  border-bottom: 1px solid #f5f5f5;
}

@media (max-aspect-ratio: 1/1) {
  .popupOverlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: white;
    z-index: 50;
  }
}
/* landscape */
@media (min-aspect-ratio: 1/1) {
  .popupContain {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 50;
  }

  .popupOverlay {
    overflow: hidden;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 40%;
    height: calc(100% - 64px);
    transform: translate(-50%, -50%);
    background-color: white;
    z-index: 50;
    border-radius: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
}
