.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1000;
}

.popup {
  background: white;
  width: 100%;
  border-radius: 16px 16px 0 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}
.title {
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
}

.closeButton {
  background: transparent;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.content {
  padding: 16px;
  width: 100%;
}

.selectBtn {
  width: 100%;
  padding: 12px 0;
  background-color: #ffe4e6;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #e8192c;
  border: 1px solid #e8192c;
  margin-bottom: 16px;
}

.unSelectBtn {
  width: 100%;
  padding: 12px 0;
  background-color: white;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  border: 1px solid #e6e6e6;
  margin-bottom: 16px;
}
