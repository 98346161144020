.tnc-wrap {
  position: fixed;
  bottom: 0;
  padding: 12px;
  padding-top: 6px;
  background: rgba(51, 51, 51, 0.8);
  font-family: SF Pro Text;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  width: 100%;
  color: #fff;
  word-wrap: break-word;
  max-height: 30%;
  overflow-y: auto;
  z-index: 999;
}

.link {
  text-decoration: underline;
}

.btn-wrap {
  text-align: right;
  padding-top: 10px;
}

#close-btn-wrap {
  text-align: right;
}

#close-btn-wrap svg {
  font-size: 1.2rem;
}
