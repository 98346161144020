.surveyjs {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1.25rem;
  width: 100%;
  height: 100%;
  position: relative;
}

.bottom {
  width: 100%;
  background-color: #f0f0f0;
  padding: 16px;
  position: sticky;
  bottom: 0;
  z-index: 10;
}

@media (max-aspect-ratio: 1/1) {
  .profileContainer {
    position: fixed;
    overflow-x: hidden;
    overflow-y: auto;
    overscroll-behavior-y: none;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
  }
  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
}

@media (min-aspect-ratio: 1/1) {
  .profileContainer {
    background-image: url("../../../../../../components//icon//assets/home-header.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    padding-top: 110px;
    position: fixed;
    overflow-x: hidden;
    overflow-y: auto;
    overscroll-behavior-y: none;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
  }
  .content {
    padding: 0px 152px;
    background-color: #f5f5f5;
    border-radius: 24px 24px 0px 0px;
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  .tips {
    color: white;
    margin-bottom: 40px;
    margin-left: 48px;
    color: #fff;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
  }
}
