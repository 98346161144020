.center {
  text-align: "center";
  padding: 10px;
}
.wrap a {
  padding-bottom: 10px;
  display: block;
  color: blueviolet;
}
.note {
  color: red;
  margin: 20px 0;
}