.container {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
}

.header {
  height: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.arrow {
  width: 24px;
  height: 24px;
  margin-left: 16px;
}

.title {
  font-size: 20px;
  font-weight: 600;
}

.text {
  font-size: 16px;
  font-weight: 500;
}
.line {
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fixedBottom {
  position: fixed;
  bottom: 20px;
  left: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  padding: 0px;
}
.logoutButton {
  width: 100%;
  padding: 12px 0;
  background-color: #ffffff;
  border: 2px solid #cccccc;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 600;
  color: #333333;
}
