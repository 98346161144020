.searchContainer {
  display: flex;
  align-items: center;
  border-radius: 16px;
  padding: 8px;
  position: relative;
  margin-left: 12px;
  margin-right: 12px;
  flex: 1;
  height: 36px;
  background-color: #f9f9f9;
}

.searchInput {
  background-color: transparent;
  border: none;
  outline: none;
  padding-left: 2px;
  flex: 1;
  padding-right: 24px;
}

.searchIcon {
  margin-right: 8px;
}

.clearButton {
  background: none;
  border: none;
  cursor: pointer;
  color: #aaa;
  font-size: 16px;
  position: absolute;
  right: 10px;
}
