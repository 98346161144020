@media (max-aspect-ratio: 1/1) {
  .profileContainer {
    position: fixed;
    overflow-x: hidden;
    overflow-y: auto;
    overscroll-behavior-y: none;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
  }

  .content {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex: 1;
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

/* landscape */
@media (min-aspect-ratio: 1/1) {
  .profileContainer {
    background-image: url("../../../../components/icon/assets/home-header.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    padding-top: 110px;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .content {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex: 1;
    overflow: auto;
    padding: 0px 152px;
    border-radius: 24px 24px 0px 0px;
    background-color: #f5f5f5;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .tips {
    color: #fff;
    margin-bottom: 40px;
    margin-left: 48px;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
  }
}
