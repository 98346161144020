/* override surveyJS default css*/
body {
  /* SurveyJS Creator V2 */
  --primary: #e8192c;
  --sjs-primary-backcolor-dark: #e8192c;
  --primary-light: #fce6eb;
  --background: #ffffff;
  --background-dim: #ffffff;
  --background-dim-light: #f9f9f9;
  --primary-foreground: #ffffff;
  --foreground: #333;
  --red: #e8192c;
  --sd-base-padding: 0;
  --sv-element-add-padding-left: 0;
  --sv-element-add-padding-right: 0;
}

.survey-common input[readonly] {
  opacity: 0.25;
}

.survey-common .sd-element--with-frame > .sd-element__erbox {
  /* The coverage style width: calc(100% + 2 * var(--sd-base-padding)) will cause the width to exceed the parent container, resulting in an unnecessary horizontal scrollbar. */
  width: 100%;
}

.survey-common .sd-element__title.sd-element__title--disabled {
  opacity: 0.5 !important;
}

.survey-common .sd-body.sd-body--responsive,
.survey-common .sd-body.sd-body--static {
  padding: 1rem !important;
}

#onboarding-join .sd-body.sd-body--responsive,
#onboarding-join .sd-body.sd-body--static {
  padding: 0rem !important;
}

.survey-common .sd-question__required-text {
  color: #e8192c !important;
}

.survey-common
  .sd-element--complex.sd-element--with-frame
  > .sd-element__header--location-top:after {
  width: 0 !important;
  bottom: 0 !important;
}

.survey-common .sd-matrixdynamic.sd-table--no-header {
  padding-top: 0 !important;
}

.survey-common .sd-matrixdynamic .sd-table__cell::before {
  font-weight: 400 !important;
  font-size: 0.75rem !important;
}

.survey-common .sd-matrixdynamic__footer .sd-matrixdynamic__btn.sd-matrixdynamic__add-btn {
  margin-left: 0 !important;
}

.survey-common a {
  color: #e8192c;
}

.survey-common .MuiFormControl-root {
  margin-top: 0 !important;
}
.survey-common .sd-element--nested.sd-element--nested-with-borders {
  border-radius: 16px;
}

.survey-common .sd-page,
.survey-common .svc-logic-question-value {
  padding: 0 !important;
}

.survey-common .sd-element--with-frame > .sd-question__erbox--below-question {
  margin-bottom: 0 !important;
  bottom: 0 !important;
}

.survey-common :not(.svc-row) > .sd-page__row.sd-row--multiple {
  /* padding-bottom: 2rem !important; */
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-top: 0 !important;
  padding-left: 0 !important;
}

.survey-common .sd-question__erbox--below-question {
  margin-top: 0px !important;
}

.survey-common .sd-element--with-frame {
  padding-bottom: 2rem !important;
  padding-top: 0 !important;
}

.survey-common .sd-element--with-frame.sd-question--error-bottom > .sd-question__content {
  margin-bottom: 0 !important;
}

.survey-common .sd-element--with-frame.sd-question--error-bottom > .sd-error.sd-element__erbox {
  padding: 8px 0 0 0 !important;
}

/* phone */
@media (max-width: 640px) {
  /* multiple first question in mobil*/
  .survey-common .sd-row--multiple > div {
    margin-bottom: 1rem !important;
  }
}

.survey-common .svc-logic-question-value {
  padding: 0 !important;
}

.survey-common .label-border:focus-within {
  border: 1px solid #444 !important;
}

.survey-common :not(.svc-row) > .sd-page__row.sd-row--multiple {
  padding-right: 0 !important;
}

/* disable question box-shadow */
.survey-common .sd-element,
.survey-common .sd-row--multiple {
  box-shadow: none !important;
}
.survey-common .sd-element--nested {
  padding-right: 0;
}
.survey-common .sd-row.sd-clearfix .sd-element--nested {
  padding-right: 0 !important;
}

.survey-common .sd-container-modern {
  margin-bottom: 0 !important;
}

.survey-common .sd-body.sd-body--static {
  max-width: none !important;
}

.survey-common .sd-question__erbox {
  background: transparent !important;
  padding-left: 0 !important;
  margin-left: 0 !important;
  margin-bottom: 0 !important;
}

/* submit btn */

.survey-common .sd-body__navigation {
  padding: 0 !important;
}

.survey-common .sd-btn {
  font-size: 1em;
  border-radius: 8px;
  width: 100%;
  max-width: 600px;
  padding: 8px !important;
}

.survey-common .sd-input {
  background-color: transparent !important;
  box-shadow: none !important;
}

.survey-common .sv-dropdown_select-wrapper > .sd-input {
  padding: 0.5rem 1.5rem 0.5rem 0 !important;
  border-bottom: 1px solid #f0f0f0 !important;
  border-radius: 0 !important;
}

.survey-common .sv-dropdown_select-wrapper > .sd-input.sd-input--readonly {
  padding: 0.5rem 0 0 0 !important;
}

.survey-common .sd-comment__content > .sd-input.sd-input--readonly {
  padding: 0 !important;
}

.survey-common #sv-nav-complete {
  width: 100%;
}

.survey-common .sd-title .sv-title-actions {
  width: 100% !important;
}

.survey-common input::placeholder {
  font-size: 1rem !important;
}

.survey-common .sd-paneldynamic__header.sd-element__header {
  padding-bottom: 1rem !important;
}

.survey-common [data-key="regionPhone0"] .sd-row--multiple {
  padding-left: 0 !important;
}

.survey-common [data-key="regionPhone0"] [data-key="phoneNumber-1"] div {
  height: 100%;
  line-height: 36px;
}

.survey-common [data-key="regionPhone0"] [data-key="phoneNumber-1"] input {
  padding-left: 8px !important;
}

.survey-common
  [data-key="regionPhone0"]
  [data-key="phoneNumber-1"]
  div.sd-question__erbox--below-question {
  height: 0;
  padding: 0;
}

.survey-common
  [data-key="regionPhone0"]
  [data-key="phoneNumber-1"]
  div.sd-question__erbox--below-question
  > div {
  padding-top: 8px;
}

.survey-common [data-key="regionPhone0"] [data-key="phoneNumber-1"] {
  padding-left: 0 !important;
}

.survey-common
  [data-key="regionPhone0"]
  .sd-row.sd-clearfix.sd-row--multiple:has(.sd-question--error) {
  padding-bottom: 28px;
}

.survey-common [data-key="regionPhone0"] .sd-paneldynamic__panels-container {
  margin: 0 !important;
  padding: 0 !important;
}

.survey-common
  [data-key="regionPhone0"]
  .sd-paneldynamic__panel-wrapper.sd-paneldynamic__panel-wrapper--list {
  padding-bottom: 0 !important;
}

.survey-common [data-key="regionPhone0"] .sd-element--nested {
  padding-right: 0 !important;
}

.survey-common [data-key="privacyPolicyAgreement0"],
.survey-common [data-key="personalInfoAgreement0"] {
  margin-top: 2.5rem !important;
}

.survey-common [data-key^="privacyPolicyAgreement"] .sd-question--left,
.survey-common [data-key^="personalInfoAgreement"] .sd-question--left {
  column-gap: 0.5rem !important;
}

.survey-common .sv-list__item-body.sd-list__item-body:has(.custom-dropdown-item) {
  padding: 0 !important;
}

.survey-common .sv-list__item-body.sd-list__item-body > .custom-dropdown-item {
  padding: 0.75rem !important;
}

#onboarding-survey [data-key="confirmationQuestions0"] .sd-element--with-frame.sd-element {
  background-color: #f5f5f5;
}

#onboarding-survey .sd-panel:not(.sd-panel--as-page) .sd-row--multiple {
  padding-left: 0 !important;
  margin-left: 0 !important;
}

#onboarding-survey [data-key="regionPhone0"] .sd-row--multiple > div {
  padding-left: 0 !important;
}

#onboarding-survey [data-key="regionPhone0"] .sd-element--nested-with-borders {
  border-width: 0 !important;
  padding-left: 0 !important;
}

#onboarding-survey [data-key="regionPhone0"] .sd-element--complex > .sd-element__header:after {
  height: 0 !important;
}

#onboarding-survey [data-key="regionPhone0"] .sd-panel__content {
  padding-top: 0 !important;
}

#onboarding-survey .sd-element--nested-with-borders > .sd-element__header--location-top:after,
.sd-element--complex.sd-element--with-frame > .sd-element__header--location-top:after {
  bottom: calc(-1 * var(--sd-base-vertical-padding));
  left: calc(-1 * var(--sd-base-padding));
  width: calc(100% + 1.5 * var(--sd-base-padding));
}

#onboarding-survey .sd-matrixdynamic__footer {
  padding-top: var(--sjs-base-unit, var(--base-unit, 8px));
  margin-bottom: 0;
}

#onboarding-survey [data-key="confirmationQuestions0"] .sd-panel__content > * {
  background-color: #fff;
  padding: 16px;
  border-radius: 12px;
  margin-bottom: 16px;
}

#onboarding-survey [data-key="formPreparation0"] .sd-element__title--disabled,
#onboarding-survey [data-key="formPreparation0"] .sd-item--disabled .sd-item__control-label {
  opacity: 1;
}

#onboarding-survey .MuiOutlinedInput-root input::placeholder {
  font-size: 0.75rem !important;
}

#onboarding-survey [data-key="formPreparation0"] .sd-element__title--disabled .sv-string-viewer {
  color: #666;
  font-weight: 400;
}
#onboarding-survey [data-key="formPreparation0"] .sd-item--disabled .sd-item__control-label {
  color: #333;
  font-weight: 600;
}

.survey-common .sd-title.sd-element__title.sd-panel__title .sv-string-viewer {
  font-size: 1.5rem;
  font-weight: 600;
}
.survey-common .sd-description .sv-string-viewer {
  font-size: 0.875rem;
}
.survey-common .sd-description.sd-panel__description {
  margin-top: 10px;
}
.survey-common .sd-title.sd-element__title.sd-question__title .sv-string-viewer {
  font-size: 0.875rem;
  font-weight: 500;
}

.survey-common .sd-description.sd-question__description .sv-string-viewer {
  font-size: 12px;
  font-weight: 500;
}

.survey-common .sd-question__header.sd-element__header.sd-question__header--location--left {
  max-width: 100%;
  flex: 1;
  margin-top: 0;
  justify-content: center;
  flex-direction: column;
  display: flex;
}

.survey-common .sd-question__content.sd-question__content--left {
  display: flex;
  align-items: center;
  flex: 0;
}

.survey-common .sv-string-viewer :last-child {
  display: inline;
}

.survey-common .sd-question--boolean.sd-question--left[data-name="privacyPolicyAgreement"] {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  /* margin: -15px auto; */
}

.survey-common
  .sd-question--boolean.sd-question--left[data-name="privacyPolicyAgreement"]
  .sd-question__content.sd-question__content--left {
  padding-left: 0;
  max-width: 10%;
}

.survey-common
  .sd-question--boolean.sd-question--left[data-name="privacyPolicyAgreement"]
  .sd-question__header.sd-element__header.sd-question__header--location--left {
  max-width: 90%;
}

.survey-common .sd-question--boolean.sd-question--left[data-name="personalInfoAgreement1"] {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  /* margin: -15px auto; */
}

.survey-common
  .sd-question--boolean.sd-question--left[data-name="personalInfoAgreement1"]
  .sd-question__content.sd-question__content--left {
  padding-left: 0;
  max-width: 10%;
}

.survey-common
  .sd-question--boolean.sd-question--left[data-name="personalInfoAgreement1"]
  .sd-question__header.sd-element__header.sd-question__header--location--left {
  max-width: 90%;
}

.survey-common .sd-question--boolean.sd-question--left[data-name="personalInfoAgreement2"] {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  /* margin: -15px auto; */
}

.survey-common
  .sd-question--boolean.sd-question--left[data-name="personalInfoAgreement2"]
  .sd-question__content.sd-question__content--left {
  padding-left: 0;
  max-width: 10%;
}

.survey-common
  .sd-question--boolean.sd-question--left[data-name="personalInfoAgreement2"]
  .sd-question__header.sd-element__header.sd-question__header--location--left {
  max-width: 90%;
}

.survey-common .sd-question--boolean.sd-question--left[data-name="personalInfoAgreement3"] {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  /* margin: -15px auto; */
}

.survey-common
  .sd-question--boolean.sd-question--left[data-name="personalInfoAgreement3"]
  .sd-question__content.sd-question__content--left {
  padding-left: 0;
  max-width: 10%;
}

.survey-common
  .sd-question--boolean.sd-question--left[data-name="personalInfoAgreement3"]
  .sd-question__header.sd-element__header.sd-question__header--location--left {
  max-width: 90%;
}

.survey-common .sd-question--boolean.sd-question--left[data-name="uploadAgreement"] {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  /* margin: -15px auto; */
}
.survey-common
  .sd-question--boolean.sd-question--left[data-name="uploadAgreement"]
  .sd-question__content.sd-question__content--left {
  padding-left: 0;
  max-width: 2%;
}

.survey-common .sd-visuallyhidden:first-child > div > div > div {
  padding: 0;
}

/* above css is common style for survey */

#onboarding-survey [data-key="dataCollectionAndConsent0"] .sd-panel__required-text {
  color: #e8192c !important;
}

#onboarding-survey [data-key="termsNConditionsSection0"] a {
  color: #4386e5;
  pointer-events: none;
  text-decoration: underline;
}

#onboarding-survey
  [data-key="dataCollectionAndConsent0"]
  .sd-description.sd-panel__description
  .sv-string-viewer {
  color: #333 !important;
  font-weight: 500;
  font-size: 0.875rem;
}

#onboarding-survey [data-key="termsNConditionsSection0"] .sd-question--boolean {
  border-radius: 12px;
  background: var(--neutrals-grey-bg, #f5f5f5);
  padding: 1rem;
  gap: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

#onboarding-survey [data-key="termsNConditionsSection0"] .sd-panel__header {
  padding-bottom: 0;
  padding-top: 0;
}

#onboarding-survey [data-key="dataCollectionAndConsent0"] .sv-string-viewer ol {
  padding: 1rem 0;
}

#onboarding-survey
  [data-key="eSignatureSection0"]
  .sd-panel__header.sd-element__header.sd-element__header--location-top {
  display: none;
}

#onboarding-survey [data-key="eSignatureSection0"] .sd-panel__content {
  padding-top: 0;
}

#onboarding-survey
  [data-key="formPreparation0"]
  .sd-title.sd-element__title.sd-panel__title
  .sv-string-viewer {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5rem;
}

#onboarding-survey
  [data-key="formPreparation0"]
  .sd-panel__header.sd-element__header.sd-element__header--location-top {
  padding-bottom: 0;
}

#onboarding-survey
  [data-key="formPreparation0"]
  .sd-element--with-frame.sd-element.sd-element--complex.sd-panel.sd-row__panel {
  /* NOTE: don't set height to 100vh, otherwise the button will overlay by survey page */
  /* height: 100vh; */
  background: #f5f5f5;
}

#onboarding-survey
  [data-key="formPreparation0"]
  .sd-element--nested.sd-element.sd-question.sd-row__question {
  background: #ffffff;
  padding: 1rem;
}

#onboarding-survey
  [data-key="documentPreparation0"]
  .sd-element--nested.sd-element.sd-question.sd-row__question {
  border-radius: 1rem 1rem 0 0;
}

#onboarding-survey
  [data-key="documentPreparation20"]
  .sd-element--nested.sd-element.sd-question.sd-row__question {
  border-radius: 0 0 1rem 1rem;
}

#onboarding-survey
  [data-key="formPreparation0"]
  .sd-panel__content
  .sd-row.sd-clearfix:nth-of-type(2) {
  margin-top: 0 !important;
  position: relative;
}

#onboarding-survey [data-key="formPreparation0"] .sd-panel__content {
  padding-top: 0;
}

#onboarding-survey
  [data-key="formPreparation0"]
  .sd-panel__content
  .sd-row.sd-clearfix:nth-of-type(2)::before {
  position: absolute;
  content: "";
  display: block;
  width: 90%;
  left: 50%;
  transform: translateX(-50%);
  height: 1px;
  z-index: 100;
  background-color: #f0f0f0;
}
/* above css is specific for onboarding form */

#onboarding-join .sd-panel__content {
  padding-top: 0;
}
#onboarding-join .sd-element--complex.sd-element--with-frame > .sd-element__header--location-top {
  padding-top: 0;
  padding-bottom: 0;
}

#personal-info .sd-panel__content {
  padding-top: 0;
  padding-bottom: 0;
}

#personal-info .sd-element--complex.sd-element--with-frame > .sd-element__header--location-top {
  padding-top: 0;
  padding-bottom: 0;
}

#personal-info .sd-question--boolean.sd-question--left[data-name="privacyPolicyAgreement2"] {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  /* margin: -15px auto; */
}

#personal-info
  .sd-question--boolean.sd-question--left[data-name="privacyPolicyAgreement2"]
  .sd-question__content.sd-question__content--left {
  padding-left: 0;
  max-width: 10%;
}

#personal-info
  .sd-question--boolean.sd-question--left[data-name="privacyPolicyAgreement2"]
  .sd-question__header.sd-element__header.sd-question__header--location--left {
  max-width: 90%;
}

#onboarding-quiz-survey .sv-title-actions__title .sv-string-viewer {
  color: var(--neutrals-grey-33, #333);
  font-feature-settings: "clig" off, "liga" off;

  /* 16_bold */
  font-family: SF Pro Text;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

#onboarding-quiz-survey .sv-title-actions__title [data-key="req-text"] {
  display: none;
}

#onboarding-join #show-popover,
#application-detail #show-popover {
  margin-right: auto;
  margin-left: 8px;
}

#onboarding-join .sd-title .sv-title-actions,
#application-detail .sd-title .sv-title-actions {
  align-items: center;
}

#training-payment {
  width: 92%;
  border-radius: 12px;
  overflow-x: hidden;
}

#application-detail {
  overflow-x: hidden;
}

#create-account .sd-element__content {
  padding-top: 0;
}

#create-account .sd-element--complex.sd-element--with-frame > .sd-element__header {
  padding-bottom: 0 !important;
}

#create-account .sd-element--with-frame.sd-question--error-bottom > .sd-question__content {
  margin-bottom: 0 !important;
}

#create-account .sd-row--fade-in {
  animation: none !important;
}

#create-account .sd-row.sd-clearfix.sd-page__row > * {
  animation: none !important;
}
