.wrap {
  padding: 10px;
  text-align: "center";
  margin-top: 20px;
}

.center {
  text-align: "center";
}
.wrap ul li {
  padding-bottom: 10px;
  color: blueviolet;
}