#personal-info
  .sd-question--boolean[data-name="privacyPolicyAgreement2"]
  .sd-question__title
  .sv-string-viewer,
#personal-info .sd-question--boolean[data-name="privacyPolicyAgreement"] .sd-question__title p {
  font-size: 12px;
  color: #333;
  font-weight: 500;
  line-height: 16px;
}
