.mainContain {
  min-height: 740px;
  height: 100vh;
  width: 100vw;
  display: flex;
  position: relative;
}

.header {
  background-repeat: no-repeat;
  background-size: cover;
}

.tips {
  color: white;
  margin-right: 8px;
}
.languageIcon {
  width: 24px;
  height: 24px;
  margin-left: 8px;
  background-size: cover;
  background-repeat: no-repeat;
}

.mainContent {
  position: relative;
  display: flex;
  flex: 1;
  width: 100%;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  padding-top: 1.5rem;
  justify-content: center;
  background-color: white;
}
.formContainer {
  position: relative;
  padding: 1.5rem;
  width: 100%;
  max-width: 28rem;
}

@media (max-aspect-ratio: 1/1) {
  .mainContain {
    flex-direction: column;
  }
  .mainContent {
    margin-top: -20px;
  }

  .header {
    background-image: url("../../../../components/icon/assets/header_bg.svg");
    width: 100vw;
    height: 208px;
  }
  .logoSection {
    position: absolute;
    top: 94px;
    left: 36px;
    display: flexbox;
    align-items: center;
  }
  .logo {
    width: 112px;
    height: auto;
    margin-bottom: 4px;
  }
  .tips {
    font-size: 14px;
  }
  .language {
    color: white;
  }
  .languageIcon {
    background-image: url("../../../../components/icon/assets/language.svg");
  }
}

/* landscape */
@media (min-aspect-ratio: 1/1) {
  .mainContain {
    flex-direction: row;
  }
  .formContainer {
    margin-top: 48px;
  }
  .header {
    background-image: url("../../../../components/icon/assets/login-bg-landscape.svg");
    width: 506px;
    height: 100vh;
  }
  .logoSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    margin-left: 40px;
  }
  .logo {
    width: 180px;
    height: auto;
    margin-bottom: 20px;
  }
  .tips {
    font-size: 28px;
  }
  .language {
    color: #1c1d1f;
  }
  .languageIcon {
    background-image: url("../../../../components/icon/assets/language-landscape.svg");
  }
  .passwordInput {
    margin-top: 30px;
  }
}

.wrap {
  padding: 10px;
  margin-top: 20px;
}
.fullScreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
}

.forgotPassword {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.forgotPasswordLabel {
  cursor: pointer;
  color: #999;
}

.buttonGroup {
  margin-top: 40px;
  width: 100%;
}

.loginButton {
  width: 100%;
  padding: 12px 0;
  margin-bottom: 16px;
  background-color: #e8192c;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 600;
  color: white;
}

.createAccountButton {
  width: 100%;
  padding: 12px 0;
  background-color: #ffe4e6;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 600;
  color: #e8192c;
}

.footer {
  position: absolute;
  flex-direction: column;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.prudentialIcon {
  margin-bottom: 8px;
  width: 100px;
  height: auto;
}

.footerText {
  font-size: 14px;
  color: #cccccc;
  display: flex;
  align-items: center;
  gap: 8px;
}

.disclaimer,
.privacyPolicy {
  text-decoration: underline;
  cursor: pointer;
}

.version {
  font-size: 14px;
  color: #cccccc;
}
.headerSection {
  position: absolute;
  top: 12px;
  right: 12px;
  display: flex;
  align-items: center;
}

.circularProgress {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup {
  position: absolute;
  top: 48px;
  right: 16px;
  background: white;
  box-shadow: 0px 8px 16px -4px #99999929;
  width: 128px;
  padding: 8px 0px 8px 0px;
  border-radius: 8px;
}

.languageItem {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
