.container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  box-sizing: border-box;
  background-color: #f5f5f5;
}

.header {
  /* background-color: #ffffff; */
  height: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.operation {
  display: flex;
  align-items: center;
}

.logoImg {
  width: 24px;
  height: 24px;
  margin-left: 16px;
}
.logoImg:hover,
.logoImg:active {
  background-color: rgba(0, 0, 0, 0.1);
}

.absolute {
  position: absolute;
}

.profile {
  /* background-color: white; */
  border-radius: 12px;
  margin-top: 132px;
  width: 768px;
  /* padding: 20px 20px 40px 20px; */
}

.profileHeader {
  display: flex;
  align-items: center;
}

.profilPicture {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}
.nameSex {
  display: flex;
  align-items: center;
}

.sexImg {
  margin-left: 8px;
  width: 16px;
  height: 16px;
}

.profileInfo {
  flex-grow: 1;
  margin-left: 12px;
}

.profileName {
  font-size: 20px;
  font-weight: bold;
  word-break: break-all;
}

.profileStatus {
  font-size: 12px;
  color: #4386e5;
  background-color: #ebf3ff;
  padding: 0px 6px;
  border-radius: 6px;
  display: inline-block;
}
.actions {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.actionItem {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.actionIcon {
  width: 32px;
  height: 32px;
  margin-bottom: 6px;
}

.profilePictureContainer {
  position: relative;
  width: 72px;
  height: 72px;
  min-width: 72px;
  border-radius: 50%;
  overflow: hidden;
}

.profileImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 33%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.editIcon {
  width: 16px;
  height: 16px;
  color: white;
}
.text {
  font-size: 12px;
  font-weight: 500;
}

.settingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  z-index: 1000;
}

.popup {
  position: absolute;
  top: 52px;
  right: 24px;
  background: white;
  box-shadow: 0px 8px 16px -4px #99999929;
  width: 240px;
  padding: 8px 0px 8px 0px;
  border-radius: 8px;
  box-shadow: 0px 8px 16px 0px #0000001a;
}
.popupItem {
  display: flex;
  align-items: center;
  padding: 12px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.popupItem:hover,
.popupItem:active {
  background-color: rgba(0, 0, 0, 0.1);
}

.logoContainer {
  position: relative;
  display: inline-block;
}

.countTextContainer {
  height: 18px;
  border-radius: 9px;
  min-width: 18px;
  background-color: #ffc959;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: -5px;
  left: 24px;
}

.countText {
  color: red;
  padding: 0px 6px;
  font-size: 12px;
  font-weight: 500;
}

.logoContainer {
  position: relative;
  display: inline-block;
}

.countTextContainer {
  height: 18px;
  border-radius: 9px;
  min-width: 18px;
  background-color: #ffc959;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: -5px;
  left: 24px;
}

.countText {
  color: red;
  padding: 0px 6px;
  font-size: 12px;
  font-weight: 500;
}

@media (max-aspect-ratio: 1/1) {
  .logoutLogo {
    display: none;
  }
  .profileContainer {
    padding: 0px;
    display: flex;
    width: 100%;
    overflow-x: hidden;
  }
  .profile {
    margin-top: 0px;
    /* padding: 20px 20px 40px 20px; */
    /* background-color: white; */
    border-radius: 0px;
    width: 100%;
    overflow-x: hidden;
  }
  .header {
    padding: 0 20px;
  }
}

/* landscape */
@media (min-aspect-ratio: 1/1) {
  .logoutLogo {
    display: inline;
  }
  .profileContainer {
    background-image: url("../../../../components/icon/assets/home-header.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    padding: 0 20px;
    background-repeat: no-repeat;
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .profile {
    /* background-color: white; */
    border-radius: 12px;
    margin-top: 132px;
    width: 768px;
  }
  .header {
    padding: 0 40px;
  }
}
