.makeStyles-switchContainer-1 {
  z-index: 999 !important;
}

#event-survey {
  word-break: break-word;
}

#event-survey .sd-title.sd-container-modern__title {
  background: #cf1819 !important;
  margin-bottom: 40px;
}

#event-survey .sd-title.sd-container-modern__title .sd-header__text .sd-title {
  color: white !important;
}

#event-survey .sd-title.sd-container-modern__title .sd-header__text .sd-description {
  color: white !important;
}
