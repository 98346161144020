.event-tag {
  @apply text-[14px] font-medium leading-[21px] md:text-2xl md:font-semibold;
}
.tag-divider {
  @apply inline-block h-[12px] md:h-5 w-[1px] bg-[#E6E6E6] relative translate-y-[1px] mx-2;
}
.divider {
  @apply w-full h-[1px] bg-[#e6e6e6] my-5 md:my-[30px];
}
.bg-mask {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) -4%, #fff 46.15%);
}
.card-shadow {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
}
.registration-dialog {
  @apply p-[28px];
}
