.root-wrap {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  margin-bottom: 30px;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-between;
}

.flex-item-left {
  flex: 50%;
  padding-right: 20px;
}

.flex-item-right {
  flex: 50%;
  padding-left: 20px;
}

.banner {
  width: 100%;
  height: 400px;
}

.banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content {
  padding: 80px;
}

.flex-item-btn {
  flex: 0.5;
  background-color: dodgerblue;
}

.title {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #333;
}

.sub-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  margin: 16px 0 36px;
  color: #666;
}

.body {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
}

.text {
  font-size: 12px;
  line-height: 16px;
}

.label {
  text-align: left;
  color: #333;
  padding-bottom: 10px;
}

.mr-0 {
  margin-right: 0 !important;
}

.top-line {
  border-top: 1px solid #f0f0f0;
  margin: 2em 0;
}

/* same default page padding as .content*/
.custom .sd-element--with-frame,
#sv-nav-complete {
  padding: 0 80px;
}

.custom .sd-element--nested {
  padding-right: 80px;
}

.custom .sd-row--multiple {
  padding-left: 40px !important;
}

@media (max-width: 1440px) {
  .content {
    padding: 60px;
  }

  .custom .sd-element--with-frame,
  #sv-nav-complete {
    padding: 0 60px;
  }

  .custom .sd-element--nested {
    padding-right: 60px;
  }

  .custom .sd-row--multiple {
    padding-left: 20px !important;
  }
}

/* Responsive layout - makes a one column-layout instead of a two-column layout */
/* 800px */
@media (max-width: 1024px) {
  .flex-item-right,
  .flex-item-left {
    flex: 100%;
    padding: 0;
  }

  .content {
    padding: 40px;
  }

  .flex-item-btn {
    flex: 1;
    background-color: dodgerblue;
  }

  .title {
    font-size: 24px;
    line-height: 28px;
  }

  .sub-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .body {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .custom .sd-element--with-frame,
  #sv-nav-complete {
    padding: 0 40px;
  }

  .custom .sd-element--nested {
    padding-right: 40px;
  }

  .custom .sd-row--multiple {
    padding-left: 0px !important;
  }
}

@media (max-width: 640px) {
  .banner {
    width: 100%;
    height: 233px;
  }

  .content {
    padding: 20px;
  }

  .title {
    font-size: 20px;
    line-height: 28px;
  }

  .sub-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }

  .body {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .custom .sd-element--with-frame,
  #sv-nav-complete {
    padding: 0 24px;
  }

  .custom .sd-element--nested {
    padding-right: 20px;
  }
}

/* override tailwind default ol,ul list-style */
.body ol {
  list-style-type: decimal;
  margin-left: 20px;
}

.body ul {
  list-style-type: disc;
  margin-left: 20px;
}

.date-picker-custom {
  margin-top: 8px !important;
}

.date-picker-custom .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #333 !important;
  border-width: 1px !important;
}

.box-phone {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}

.box-item-left {
  flex: 0.36;
  margin-right: 10px;
}

.box-item-right {
  flex: 0.64;
}

.error-tips {
  text-align: left;
  color: #e8192c;
}

/*
.MuiPickersLayout-toolbar {
   display: none !important;
}*/
