.content {
  padding: 16px;
  width: 100%;
}

.selectBtn {
  width: 100%;
  padding: 12px 0;
  background-color: #ffe4e6;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #e8192c;
  border: 1px solid #e8192c;
  margin-bottom: 16px;
}

.unSelectBtn {
  width: 100%;
  padding: 12px 0;
  background-color: white;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  border: 1px solid #e6e6e6;
  margin-bottom: 16px;
}
