.loading {
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-container {
  width: 100%;
  flex: 1;
  display: flex;
}
.flex1 {
  flex: 1;
  display: flex;
}
.flex-column {
  flex-direction: column;
}

.modal-title {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-item {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  padding: 0;
}

.modal-item-spacer {
  width: 100%;
  height: 1px;
  background: #f0f0f0;
  margin-bottom: 14px;
  margin-top: 14px;
}

.padding {
  padding: 0 16px 16px 16px;
}

.bottom0 {
  bottom: 0;
}
.docColor {
  color: #4386e5;
}
.headerKey {
  color: var(--neutrals-grey-66, #666);
  /* 16_reg */
  font-size: 16px;
  /* font-family: SF Pro Text; */
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.gap {
  height: 12px;
}
.headerValue {
  color: var(--neutrals-grey-33, #333);
  text-align: right;

  /* 16_med */
  font-size: 16px;
  /* font-family: SF Pro Text; */
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.docText {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #999999;
}

.padding-left-right {
  padding-left: 16px;
  padding-right: 16px;
}
.greybg {
  background-color: #f5f5f5;
}
.item:last-child {
  margin-bottom: 0;
}
.item {
  background-color: #ffffff;
  border-radius: 12px;
  margin-bottom: 16px;
}
.item-child:last-child {
  margin-bottom: 0;
}
.item-child {
  background-color: #f5f5f5;
  border-radius: 8px;
  margin-bottom: 12px;
}
.width {
  width: 100%;
}

.header-li {
  display: flex;
  justify-content: space-between;
}

.header-h2 {
  font-size: 28px;
  font-weight: bold;
}
