.wrap {
  padding: 10px;
}

.wrap ul li {
  padding-bottom: 10px;
  color: blueviolet;
}

.fixed-top-right {
  position: fixed;
  top: 160px;
  right: 16px;
  background: #fff;
  border-radius: 50%;
  border: 1px solid #eee;
  /* fix arl entry is covered by PDF resource */
  z-index: 1;
}
