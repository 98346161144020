input[type="password"] {
  -webkit-text-security: disc; /* Safari */
}

input[type="password"]::-webkit-inner-spin-button {
  display: none;
}

input[type="password"]::-ms-reveal {
  display: none;
}

input[type="password"] {
  padding-right: 30px;
}
